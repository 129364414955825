import type { FilterableBadgeCategory } from '@biteinc/enums';

import type { KioskMenuItem } from '~/types';

export namespace MenuItemUtils {
  export function matchesBadgesInSet(
    item: KioskMenuItem,
    badgeIdsByCategory: Record<FilterableBadgeCategory, string[]>,
  ): boolean {
    const { restrictive, ...nonRestrictiveBadgeIdsByCategory } = badgeIdsByCategory;

    const matchesNonRestrictive = Object.values(nonRestrictiveBadgeIdsByCategory).some(
      (category: string[]) => {
        return category.some((badgeId) => item.badgeIds?.includes(badgeId));
      },
    );
    if (restrictive.length > 0) {
      // if there are restrictive badges, then the item must not match any of them
      if (restrictive.some((badgeId) => item.badgeIds?.includes(badgeId))) {
        return false;
      }
      // we want to check if there are any non-restrictive badges
      // because if there isnt, then we want to return true
      if (
        Object.values(nonRestrictiveBadgeIdsByCategory).some(
          (category: string[]) => category.length > 0,
        )
      ) {
        return matchesNonRestrictive;
      }
      return true;
    }
    // otherwise, the item must match at least one badge in any category
    return matchesNonRestrictive;
  }
}
