import React from 'react';

import { Strings } from '@biteinc/common';
import { Button, Dialog, DialogContent, DialogTrigger } from '@biteinc/ui';

import { useLocalize } from '~/app/js/localization/localization';
import { useLocation, useLoyaltyAuthData, useLoyaltyStatus, usePreSelectedRewards } from '~/stores';

import { LoyaltyAccount } from './loyalty-account';

const ShowGuestData = (): JSX.Element => {
  const loyaltyAuthData = useLoyaltyAuthData();
  const str = useLocalize();
  const status = useLoyaltyStatus();

  const location = useLocation();
  const { hideStatusAndPoints } = location.getLoyaltyIntegration()!;

  const classNames =
    'loyalty-account-button-inner tw-text-[var(--color-primary)] tw-text-2xl tw-tracking-normal tw-text-center tw-overflow-hidden tw-text-ellipsis';

  if (loyaltyAuthData) {
    if (status && !hideStatusAndPoints) {
      return (
        <div
          className={classNames}
          dangerouslySetInnerHTML={{ __html: status }}
        />
      );
    }
    return <div className={classNames}>{str(Strings.CUSTOMER_ACCOUNT_BUTTON_LOGGED_IN)}</div>;
  }
  return <div className={classNames}>{str(Strings.CUSTOMER_ACCOUNT_BUTTON_LOG_IN)}</div>;
};

export const LoyaltyAccountButton = (): JSX.Element => {
  const preSelectedRewards = usePreSelectedRewards();

  const [open, setOpen] = React.useState(false);

  return (
    <Dialog
      open={open}
      onOpenChange={setOpen}
    >
      <DialogTrigger asChild>
        <Button
          className={`loyalty-account-button tw-flex tw-flex-row tw-items-center tw-justify-evenly tw-rounded-md tw-border tw-border-solid tw-border-[var(--color-primary)] tw-bg-white hover:tw-bg-gray-100 tw-h-[48px] tw-w-[190px] tw-px-2 tw-py-1 tw-m-1 tw-whitespace-nowrap ${
            preSelectedRewards?.length ? 'tw-justify-between' : ''
          }`}
          uncolored={true}
        >
          <ShowGuestData />
          {preSelectedRewards && preSelectedRewards.length > 0 && (
            <div className="loyalty-account-reward-count">{preSelectedRewards.length}</div>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="tw-min-h-[500px] tw-w-[60%]"
        onOpenAutoFocus={(event) => {
          event.preventDefault();
        }}
      >
        <LoyaltyAccount closeModal={() => setOpen(false)} />
      </DialogContent>
    </Dialog>
  );
};

export default LoyaltyAccountButton;
