import _ from 'underscore';

import { MenuItemCategory, MenuItemSaleUnit } from '@biteinc/enums';

import { MenuItemUtils } from '~/helpers';

import GcnHtml from '../gcn_html';
import { GCNModel } from './gcn_model';
import { GCNPriceOption } from './gcn_price_option';

export const GCNMenuItem = GCNModel.extend({
  initialize(...args) {
    GCNModel.prototype.initialize.apply(this, args);

    this.priceOptions = _.map(this.get('priceOptions'), (poJSON) => {
      return new GCNPriceOption(poJSON);
    });
  },

  isWeighable() {
    return this.priceOptions.some((priceOption) => {
      return [MenuItemSaleUnit.OZ, MenuItemSaleUnit.LB].includes(priceOption.get('saleUnit'));
    });
  },

  getSaleUnit() {
    return this.priceOptions[0].get('saleUnit');
  },

  _isScannable() {
    return _.any(this.priceOptions, (priceOption) => {
      return priceOption.get('barcode') || priceOption.get('posBarcode');
    });
  },

  isRetail() {
    return this.isWeighable() || this._isScannable() || this.get('isRetail');
  },

  isSimpleForRecommendations() {
    return this.priceOptions.every((priceOption) => {
      // It's very tedious to select mod groups for recommendations when the screen reader is on
      return !priceOption.addonSets?.length;
    });
  },

  canBeOpened() {
    if (this.is86d()) {
      return false;
    }
    if (this.hasImages()) {
      return true;
    }
    if (this.displayStoryHtml().length) {
      return true;
    }
    const hasAnyAddonSets = this.priceOptions.some((priceOption) => {
      return priceOption.addonSets?.length > 0;
    });
    if (hasAnyAddonSets) {
      return true;
    }

    return true;
  },

  addonSetsHaveCalories() {
    let hasCalories = false;
    _.each(this.priceOptions[0].addonSets, (addonSet) => {
      _.each(addonSet.items, (addonSetItem) => {
        if (addonSetItem.getCalorieRange()) {
          hasCalories = true;
        }
      });
    });
    return hasCalories;
  },

  getCaloriesHtml() {
    if (this.shouldShowNutritionInfo()) {
      const calorieRange = this.getCalorieRange();
      const calorieSuffix = gcn.menu.settings.get('calorieSuffix') || 'Cals';
      if (!isNaN(calorieRange?.min)) {
        return `<div class="calories"><span class="calories-total">${GcnHtml.stringFromCalorieRange(
          calorieRange,
        )}</span> ${calorieSuffix}</div>`;
      }
      return `<div class="calories hidden"><span class="calories-total">0</span> ${calorieSuffix}</div>`;
    }
    return undefined;
  },

  getCaloriesAriaLabel() {
    if (this.shouldShowNutritionInfo()) {
      const calorieRange = this.getCalorieRange();
      const calorieSuffix = gcn.menu.settings.get('calorieSuffix') || 'Calories';
      if (!isNaN(calorieRange?.min)) {
        return `${GcnHtml.stringFromCalorieRange(calorieRange).replace(
          '-',
          ' to ',
        )} ${calorieSuffix}`;
      }
      return `0 ${calorieSuffix}`;
    }
    return undefined;
  },

  displayNameHtml() {
    return GcnHtml.htmlFromString(this.displayNameWithoutCalories());
  },

  displayNameWithoutCalories() {
    const name = this.displayName();

    // Strip out calories from name
    // TODO: Clean up when old nutritional values are all removed.
    const matches = name.match(/(([0-9]+ \| )*[0-9]+ cal)$/i);
    if (_.size(matches)) {
      return name.replace(matches[0], '');
    }
    return name;
  },

  getCalorieRange() {
    const nutritionInfo = [];

    this.priceOptions.forEach((priceOption) => {
      if (priceOption.get('nutritionInfo')) {
        nutritionInfo.push(priceOption.get('nutritionInfo'));
      }
    });

    if (!nutritionInfo.length) {
      return null;
    }

    const allBaseCalories = this.priceOptions.map(
      (po) =>
        po.get('nutritionInfo')?.baseCalories ?? po.get('nutritionInfo')?.posBaseCalories ?? 0,
    );
    const allMaxCalories = this.priceOptions.map(
      (po) => po.get('nutritionInfo')?.maxCalories ?? po.get('nutritionInfo')?.posMaxCalories ?? 0,
    );

    const baseCalories = Math.min(...allBaseCalories);
    const maxBaseCalories = Math.max(...allMaxCalories);

    const range = {
      min: baseCalories ? baseCalories : null,
      max: maxBaseCalories > 0 ? maxBaseCalories : null,
    };

    if (_.isNumber(range.min)) {
      if (!_.isNumber(range.max)) {
        range.max = range.min;
      }
      return range;
    }

    return null;
  },

  shouldShowNutritionInfo() {
    if (!gcn.menu.settings.get('showNutritionInfo')) {
      return false;
    }
    if (this.getCalorieRange()) {
      return true;
    }
    if (this.addonSetsHaveCalories()) {
      return true;
    }

    const matches = this.displayName().match(/(([0-9]+ \| )*[0-9]+ cal)$/i);
    return _.size(matches) > 0;
  },

  displayDescriptionHtml() {
    let desc = this.displayDescription() || '';
    // Isolate the spirit region into a separate span so it can be hidden
    if (this.get('spiritId')) {
      const parts = desc.split(', ');
      if (parts.length >= 4) {
        let hasSeenABV = false;
        desc = '';
        for (let i = parts.length - 1; i >= 0; i--) {
          const part = (i > 0 ? ', ' : '') + parts[i];
          if (parts[i].toLowerCase().indexOf('abv') < 0 && !hasSeenABV && i > parts.length - 3) {
            desc = `<span class="spirit-region">${part}</span>${desc}`;
          } else {
            desc = part + desc;
            hasSeenABV = true;
          }
        }
      }
    }
    return GcnHtml.htmlFromString(desc);
  },

  displayStoryHtml() {
    return GcnHtml.htmlFromString(this.get('story'));
  },

  badges() {
    const badges = [];
    _.each(this.get('badgeIds'), (badgeId) => {
      const badge = gcn.menu.getBadgeWithId(badgeId);
      if (badge) {
        badges.push(badge);
      }
    });
    return badges;
  },

  /**
   * @param {string} badgeId
   * @returns {boolean}
   */
  hasBadgeWithId(badgeId) {
    return (this.get('badgeIds') || []).includes(badgeId);
  },

  hasImages() {
    return this.hasArr('images');
  },

  getImageUrlOrPlaceholder() {
    if (this.hasImages()) {
      return this.get('images')[0].url;
    }
    switch (this.get('category')) {
      case MenuItemCategory.AlcoholicBeverage:
        return 'https://assets.getbite.com/images-default/alc-bev-placeholder.png';
      case MenuItemCategory.NonalcoholicBeverage:
        return 'https://assets.getbite.com/images-default/non-alc-placeholder.png';
      // For category food and all categories not covered
      default:
        return 'https://assets.getbite.com/images-default/food-placeholder.png';
    }
  },

  hasOnePriceOptionNoAddons() {
    if (this.priceOptions.length === 1) {
      return this.priceOptions[0].addonSets.length === 0;
    }
    return false;
  },

  /**
   * @param {Record<BadgeCategory, string[]>} badgeIdsByCategory
   */
  matchesBadgesInSet(badgeIdsByCategory) {
    return MenuItemUtils.matchesBadgesInSet(this.attributes, badgeIdsByCategory);
  },

  getPriceOptionWithId(priceOptionId) {
    return _.find(this.priceOptions, (priceOption) => {
      return priceOption.id === priceOptionId;
    });
  },

  getPriceOptionWithBarcode(barcode) {
    return _.find(this.priceOptions, (priceOption) => {
      const barcodes = priceOption.get('barcode') ? priceOption.get('barcode').split(',') : [];
      const posBarcodes = priceOption.get('posBarcode')
        ? priceOption.get('posBarcode').split(',')
        : [];
      return barcodes.includes(barcode) || posBarcodes.includes(barcode);
    });
  },

  is86d() {
    return this.priceOptions.every((priceOption) => priceOption.isHidden());
  },

  // Returns true if this is a build-your-own item.
  isBuildYourOwnItem() {
    return this.hasArr('buildYourOwnBaseImage');
  },

  getAddonSetIfAssorted() {
    return (
      this.hasArr('assortedBaseImage') &&
      this.priceOptions.length === 1 &&
      this.priceOptions[0].addonSets.find((addonSet) => {
        return addonSet.isAssorted();
      })
    );
  },

  posId() {
    return (this.get('posId') || '').toString();
  },
});
