import ReactGA from 'react-ga4';
import { v4 as uuid } from 'uuid';

import { BiteUrl, ErrorCode, Log } from '@biteinc/common';
import type { GarconBridgeMessageValues, OrderChannel } from '@biteinc/enums';
import {
  ApiHeader,
  ClientApiVersion,
  Environment,
  FlashBridgeMessage,
  GarconBridgeMessage,
} from '@biteinc/enums';

import pkg from '../../../package.json';
import type { BridgeCallback, BridgeResponse } from './gcn_bridge_interface';
import { ApiService } from './gcn_maitred_request_manager';

enum BridgeMessageVersion {
  V1 = '1',
}

type BridgeMessage = {
  type: GarconBridgeMessageValues;
  data?: any;
  version: BridgeMessageVersion;
  messageId: string;
  responseId?: string;
};

export default class GcnGarconBridge {
  constructor(
    private readonly apiHost: string,
    private readonly orgIdStr: string,
    private readonly orderChannel: OrderChannel,
    private readonly locationIdStr: string,
  ) {
    // disable ReactGA when in test env
    if (window.env !== Environment.TEST) {
      const googleAnalyticsApiKey = gcn.location.get('googleAnalyticsApiKey');
      if (googleAnalyticsApiKey) {
        ReactGA.initialize(googleAnalyticsApiKey);
      }
    }
  }

  private unfulfilledPromises: {
    [messageId: string]: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      resolve: (value?: any) => void;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      reject: (reason?: any) => void;
    };
  } = {};

  init(): void {
    window.addEventListener(
      'garconMessage',
      async (event: CustomEvent<BridgeMessage>) => {
        await this.handleIncomingMessage(event);
      },
      false,
    );
  }

  async send<T>(data: any, callback?: BridgeCallback): Promise<T> {
    // Hack while gcn still uses callbacks instead of promises
    if (callback) {
      const result = await this.sendAsync<T>(data).catch((err) => ({ error: err }));
      callback(result);
      return result as any;
    }

    return this.sendAsync(data);
  }

  private getHostFromApiService(apiService: ApiService): string {
    switch (apiService) {
      case ApiService.LogsApiV1:
      case ApiService.LoyaltyApiV1:
      case ApiService.LoyaltyApiV2:
      case ApiService.Maitred:
      case ApiService.OrdersApiV1:
      case ApiService.OrdersApiV2:
      case ApiService.PaymentsApiV2:
      case ApiService.RecommendationsApiMaitred:
        return this.apiHost;
      case ApiService.RecommendationsApi:
        return BiteUrl.biteGatewayUrl(window.env);
    }
  }

  /**
   * Transform messages from the old format to the new format
   */
  async sendAsync<T>(data: any): Promise<T> {
    switch (data.event) {
      case FlashBridgeMessage.READY:
        return this.sendGarconMessage(GarconBridgeMessage.READY, {
          version: pkg.version,
        });

      case FlashBridgeMessage.MAITRED_REQUEST: {
        const { apiService, method, path, timeout, body } = data.request;

        return new Promise((resolve, reject) => {
          const url = `${this.getHostFromApiService(apiService)}${path}`;
          Log.info(`maitred -> ${method}: ${url}`);

          // We think the jQuery types are bad and this thing doesn't actually return a promise.
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          $.ajax({
            url,
            type: method,
            dataType: 'json',
            timeout,
            contentType: 'application/json',
            headers: {
              [ApiHeader.ApiVersion]: `${ClientApiVersion.SupportsErrorMessage}`,
              [ApiHeader.LocationId]: this.locationIdStr,
              [ApiHeader.OrgId]: this.orgIdStr,
              ...(this.orderChannel && {
                [ApiHeader.OrderChannel]: this.orderChannel,
              }),
            },
            success(result: BridgeResponse /* status, xhr */) {
              Log.info(`maitred <- ${method}: ${url} 200`);
              resolve(result);
            },
            error(result: any) {
              Log.info(`maitred <- ${method}: ${url} ${result.status}`);
              const error = {
                code: ErrorCode.NetworkError,
                ...(result.responseJSON || {}),
              } as Error;

              reject(error);
            },
            ...(['POST', 'DELETE', 'PUT'].includes(method) &&
              body && {
                data: JSON.stringify(body),
              }),
          });
        });
      }

      case FlashBridgeMessage.GO_HOME: {
        return this.sendGarconMessage(GarconBridgeMessage.SESSION_END);
      }

      case FlashBridgeMessage.MAKE_PAYMENT: {
        return this.sendGarconMessage(GarconBridgeMessage.MAKE_PAYMENT, {
          amount: data.amountInCents,
          order: data.order,
        });
      }

      case FlashBridgeMessage.PRINT_RECEIPT:
      case FlashBridgeMessage.USER_DID_REQUEST_TO_PRINT_RECEIPT:
        return this.sendGarconMessage(GarconBridgeMessage.EXECUTE_PRINT_JOBS, {
          printJobs: data.printPayload.printJobs,
        });

      case FlashBridgeMessage.ORDER_COMPLETED:
        return { success: true } as any as T;

      case FlashBridgeMessage.IMAGE:
        return data.imageUrl;

      case FlashBridgeMessage.TRACK_EVENT:
        if (gcn.location.get('googleAnalyticsApiKey') && window.isFlash) {
          const googleAnalyticsApiKey = gcn.location.get('googleAnalyticsApiKey');
          if (!ReactGA.isInitialized && googleAnalyticsApiKey) {
            ReactGA.initialize(googleAnalyticsApiKey);
          }
          ReactGA.event(data.eventName, data.eventData);
        }
        return {} as T;

      case FlashBridgeMessage.START_SCANNER:
        return this.sendGarconMessage(GarconBridgeMessage.START_SCANNER);
      case FlashBridgeMessage.STOP_SCANNER:
        return this.sendGarconMessage(GarconBridgeMessage.STOP_SCANNER);

      // Silently swallow these events for demo purposes
      case FlashBridgeMessage.DEVICE_GAZEBO_LOG: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { event: _, ...eventData } = data;
        return this.sendGarconMessage(GarconBridgeMessage.DEVICE_GAZEBO_LOG, {
          ...eventData,
        });
      }
      case FlashBridgeMessage.ENABLE_REDUCED_VIEWPORT_HEIGHT: {
        return this.sendGarconMessage(GarconBridgeMessage.ENABLE_REDUCED_VIEWPORT_HEIGHT);
      }
      case FlashBridgeMessage.DISABLE_REDUCED_VIEWPORT_HEIGHT: {
        return this.sendGarconMessage(GarconBridgeMessage.DISABLE_REDUCED_VIEWPORT_HEIGHT);
      }
      case FlashBridgeMessage.ENABLE_ZOOM: {
        return this.sendGarconMessage(GarconBridgeMessage.ENABLE_ZOOM);
      }
      case FlashBridgeMessage.SOCKET_REQUEST: {
        return this.sendGarconMessage(GarconBridgeMessage.SOCKET_REQUEST, {
          address: data.address,
          payload: data.payload,
        });
      }
      case FlashBridgeMessage.DISABLE_ZOOM: {
        return this.sendGarconMessage(GarconBridgeMessage.DISABLE_ZOOM);
      }
      case FlashBridgeMessage.READ_GIFT_CARD: {
        return this.sendGarconMessage(GarconBridgeMessage.READ_GIFT_CARD, {
          amount: data.amountInCents,
          order: data.order,
        });
      }
      case FlashBridgeMessage.CANCEL_READ_GIFT_CARD: {
        return this.sendGarconMessage(GarconBridgeMessage.CANCEL_READ_GIFT_CARD);
      }
      case FlashBridgeMessage.UPDATED_SESSION_DATA:
      case FlashBridgeMessage.USER_DID_ENTER_CRITICAL_PERIOD:
      case FlashBridgeMessage.USER_DID_LEAVE_CRITICAL_PERIOD:
      case FlashBridgeMessage.USER_DID_FOCUS_ON_TEXT_FIELD:
      case FlashBridgeMessage.USER_DID_FOCUS_OUT_OF_TEXT_FIELD:
      case FlashBridgeMessage.SENT_PAYMENT_TO_MAITRED:
        return {} as T;

      case GarconBridgeMessage.ON_PREM_REQUEST: {
        Log.debug('GCN -> Garcon -> ON_PREM_REQUEST', data);
        return this.sendGarconMessage(GarconBridgeMessage.ON_PREM_REQUEST, {
          ...data.onPremRequest,
        });
      }
      default:
        throw Error(`Unsupported event: ${data.event}`);
    }
  }

  async sendGarconMessage<T>(
    type: GarconBridgeMessageValues,
    data?: any,
    responseId?: string,
  ): Promise<T> {
    const event = new CustomEvent<BridgeMessage>('gcnMessage', {
      detail: {
        type,
        data,
        version: BridgeMessageVersion.V1,
        messageId: uuid(),
        ...(responseId && { responseId }),
      },
    });

    const promise = new Promise<T>((resolve, reject) => {
      this.unfulfilledPromises[event.detail.messageId] = { resolve, reject };
    });

    window.parent.dispatchEvent(event);

    return promise;
  }

  private async handleIncomingMessage(event: CustomEvent<BridgeMessage>): Promise<void> {
    const message = event.detail;

    if (message.responseId) {
      this.unfulfilledPromises[message.responseId]?.resolve(message.data);
      delete this.unfulfilledPromises[message.responseId];
    }

    // Translate incoming events to the old format
    switch (message.type) {
      case GarconBridgeMessage.SESSION_START: {
        gcn.handleBridgeMessage({
          kioskSessionStart: true,
          ...message.data,
        });
        break;
      }
      case GarconBridgeMessage.SHOW_REFUNDER: {
        gcn.handleBridgeMessage({
          showRefunder: { passcode: message.data.passcode },
        });
        break;
      }
      case GarconBridgeMessage.SEND_SCANNER_DATA: {
        gcn.handleBridgeMessage({ scannerData: message.data.scannerData });
        break;
      }
      case GarconBridgeMessage.SEND_USERS: {
        gcn.handleBridgeMessage({ users: message.data.users });
        break;
      }
      case GarconBridgeMessage.MENU_UPDATE: {
        gcn.handleBridgeMessage({ menuUpdate: true });
        break;
      }
    }

    Log.debug('Garcon -> GCN', message);
  }
}
