import { IonIcon, IonToast } from '@ionic/react';
import classNames from 'classnames';
import { close } from 'ionicons/icons';
import * as React from 'react';

import { Strings } from '@biteinc/common';
import { BadgeCategory, BadgeCategoryHelper } from '@biteinc/enums';
import type { FilterableBadgeCategory } from '@biteinc/enums';

import { useLocalize } from '~/app/js/localization/localization';
import { MenuItemUtils } from '~/helpers';
import type { KioskMenu } from '~/types';

import { useMenu, useStore } from '../stores';
import { ReactAriaButton } from './button';

type MenuFilterPickerProps = {
  close: () => void;
};

export function MenuFilterPicker(props: MenuFilterPickerProps): JSX.Element {
  const str = useLocalize();
  const menu = useMenu();
  const menuFilters = useStore((state) => state.config.menuFilters);
  const clearMenuFilters = useStore((state) => state.config.clearMenuFilters);
  const removeMenuFilter = useStore((state) => state.config.removeMenuFilter);
  const addMenuFilter = useStore((state) => state.config.addMenuFilter);

  const badges = menu.badges.reduce<Record<FilterableBadgeCategory, KioskMenu['badges'][number][]>>(
    (acc, badge) => {
      if (!BadgeCategoryHelper.isFilterableCategory(badge.filterCategory)) {
        return acc;
      }

      if (badge.filterCategory) {
        acc[badge.filterCategory].push(badge);
      }

      return acc;
    },
    {
      [BadgeCategory.Dietary]: [],
      [BadgeCategory.Restrictive]: [],
      [BadgeCategory.Other]: [],
    },
  );

  function toggleFilter(filter: KioskMenu['badges'][number]): void {
    if (BadgeCategoryHelper.isFilterableCategory(filter.filterCategory)) {
      const data = {
        category: filter.filterCategory,
        id: filter._id,
      };
      if (menuFilters[data.category].includes(filter._id)) {
        removeMenuFilter(data);
      } else {
        addMenuFilter(data);
      }
    }
  }

  function shouldShowNoMatchingItems(): boolean {
    return (
      Object.values(menuFilters).some((ids) => ids.length > 0) &&
      !menu.items.some((item) => MenuItemUtils.matchesBadgesInSet(item, menuFilters))
    );
  }

  const showNoMatchingItems = shouldShowNoMatchingItems();
  return (
    <div className="menu-filter-picker tw-flex tw-flex-col tw-text-white tw-flex-1">
      <div className="tw-flex tw-flex-col tw-flex-1 tw-space-y-4 tw-p-4 tw-mx-auto max-w-[700px]">
        <div className="tw-flex tw-items-center tw-justify-center tw-space-x-10">
          <h2 className="menu-filter-picker-header tw-uppercase tw-text-2xl tw-font-bold">
            {str(Strings.MENU_FILTERS_NUTRITION_PREFERENCE)}
          </h2>
          <ReactAriaButton
            className="menu-filter-picker-close-button tw-p-8 tw-rounded-full tw-text-2xl"
            onPress={() => {
              props.close();
            }}
          >
            <IonIcon
              slot="icon-only"
              icon={close}
            />
          </ReactAriaButton>
        </div>
        {badges.restrictive.length > 0 && (
          <div className="menu-filter-picker-section tw-flex tw-flex-col tw-space-y-1">
            <h6 className="menu-filter-picker-section-title tw-text-lg">
              {str(Strings.MENU_FILTERS_RESTRICTIVE_FILTERS)}
            </h6>
            <p className="menu-filter-picker-section-description tw-text-sm">
              {str(Strings.MENU_FILTERS_RESTRICTIVE_FILTERS_DESCRIPTION)}
            </p>
            <div className="tw-flex tw-items-center tw-space-x-4 tw-mt-2">
              {badges.restrictive.map((filter) => (
                <FilterButton
                  key={filter._id}
                  filter={filter}
                  isSelected={menuFilters.restrictive.includes(filter._id)}
                  onPress={() => toggleFilter(filter)}
                />
              ))}
            </div>
          </div>
        )}
        {badges.dietary.length > 0 && (
          <div className="menu-filter-picker-section tw-flex tw-flex-col tw-space-y-1">
            <h6 className="menu-filter-picker-section-title tw-text-lg">
              {str(Strings.MENU_FILTERS_DIETARY_FILTERS)}
            </h6>
            <p className="menu-filter-picker-section-description tw-text-sm">
              {str(Strings.MENU_FILTERS_DIETARY_FILTERS_DESCRIPTION)}
            </p>
            <div className="tw-flex tw-items-center tw-space-x-4 tw-mt-2">
              {badges.dietary.map((filter) => (
                <FilterButton
                  key={filter._id}
                  filter={filter}
                  isSelected={menuFilters.dietary.includes(filter._id)}
                  onPress={() => toggleFilter(filter)}
                />
              ))}
            </div>
          </div>
        )}
        {badges.other.length > 0 && (
          <div className="menu-filter-picker-section tw-flex tw-flex-col tw-space-y-1">
            <h6 className="menu-filter-picker-section-title tw-text-lg">
              {str(Strings.MENU_FILTERS_OTHER_FILTERS)}
            </h6>
            <p className="menu-filter-picker-section-description tw-text-sm">
              {str(Strings.MENU_FILTERS_OTHER_FILTERS_DESCRIPTION)}
            </p>
            <div className="tw-flex tw-items-center tw-space-x-4 tw-mt-2">
              {badges.other.map((filter) => (
                <FilterButton
                  key={filter._id}
                  filter={filter}
                  isSelected={menuFilters.other.includes(filter._id)}
                  onPress={() => toggleFilter(filter)}
                />
              ))}
            </div>
          </div>
        )}
        <IonToast
          isOpen={showNoMatchingItems}
          color="danger"
          message={str(Strings.NO_ITEMS_MATCH_FILTERS).toString()}
          cssClass="modal-toast"
        />
      </div>
      <div className="menu-filter-picker-footer tw-flex tw-items-center tw-justify-center tw-space-x-4 tw-bg-[#333] tw-p-4">
        <ReactAriaButton
          style={{
            //@ts-expect-error
            '--bg-color': '#333',
            '--text-color': 'rgb(200,199,204)',
          }}
          className="menu-filter-picker-footer-button tw-flex tw-items-center tw-justify-center tw-py-2 tw-px-16 tw-rounded-md tw-bg-[--bg-color] tw-text-[--text-color] tw-border tw-border-solid tw-border-[--text-color] tw-font-bold hover:tw-opacity-80 data-[pressed]:tw-opacity-80 tw-min-w-[275px]"
          onPress={() => {
            clearMenuFilters();
          }}
        >
          {str(Strings.CLEAR_ALL)}
        </ReactAriaButton>
        <ReactAriaButton
          style={{
            //@ts-expect-error
            '--bg-color': 'rgb(200,199,204)',
            '--text-color': '#333',
          }}
          className="menu-filter-picker-footer-button tw-flex tw-items-center tw-justify-center tw-py-2 tw-px-16 tw-rounded-md tw-bg-[--bg-color] tw-text-[--text-color] tw-border tw-border-solid tw-border-[--text-color] tw-font-bold hover:tw-opacity-80 data-[pressed]:tw-opacity-80 tw-min-w-[275px]"
          onPress={() => {
            props.close();
          }}
          disabled={showNoMatchingItems}
        >
          {str(Strings.BACK_TO_MENU)}
        </ReactAriaButton>
      </div>
    </div>
  );
}

function FilterButton(props: {
  isSelected: boolean;
  onPress: () => void;
  filter: KioskMenu['badges'][number];
}): JSX.Element {
  const str = useLocalize();
  const { onPress, isSelected } = props;
  const label = props.filter.name;
  const icon = props.filter.icons[0].url;

  return (
    <ReactAriaButton
      className={classNames(
        `menu-filter-picker-filter-button tw-font-[--font-title] tw-rounded-lg tw-flex tw-space-x-2 tw-items-center tw-p-2 tw-pr-6 tw-min-w-[180px]`,
        {
          'tw-text-black [--bg-opacity:1] hover:[--bg-opacity:0.9] data-[pressed]:[--bg-opacity:0.9]':
            isSelected,
          '[--bg-opacity:0.3] hover:[--bg-opacity:0.8] data-[pressed]:[--bg-opacity:0.8]':
            !isSelected,
        },
      )}
      style={{
        backgroundColor: `rgba(255,255,255,var(--bg-opacity))`,
      }}
      data-selected={isSelected ? '' : undefined}
      onPress={onPress}
      aria-label={str(label).toString()}
    >
      <span
        className="menu-filter-picker-filter-button-icon tw-bg-[--card-background] tw-bg-center tw-bg-no-repeat tw-bg-cover tw-border-white tw-border-solid tw-border-2 tw-h-9 tw-w-9 tw-rounded-full tw-p-1.5"
        style={{ backgroundImage: `url(${icon})` }}
      />
      <span className="menu-filter-picker-filter-button-label tw-font-bold">{str(label)}</span>
    </ReactAriaButton>
  );
}
